import React, { useState } from 'react';
import { MessageCircle, X } from 'react-feather';
import { trackEvent } from './utils/amplitude';
import './App.css';

const FeedbackButton = ({ username }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [message, setMessage] = useState('');

    const toggleFeedback = () => {
        // Track when feedback form is opened or closed
        trackEvent('feedback_form_toggled', {
            action: isOpen ? 'closed' : 'opened',
            username: username ? username : 'username not found'
        });
        setIsOpen(!isOpen);
    };

    const handleSubmit = async () => {
        if (!feedback.trim() || !username.trim()) {
            setMessage('Feedback and username are required.');
            // Track validation error
            trackEvent('feedback_validation_error', {
                username,
                error: 'missing_required_fields'
            });
            return; // Prevent the request from being sent
        }
        
        const token = localStorage.getItem('token'); // Retrieve token from localStorage
        try {
            // Replace with your API logic
            const response = await fetch('https://proto-backend-d2c1638486b9.herokuapp.com/feedback', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`, // Correctly include the token
                },
                body: JSON.stringify({
                    feedback,
                    username,
                }),
            });
            if (response.ok) {
                // Track successful feedback submission
                trackEvent('feedback_submitted', {
                    username,
                    feedbackLength: feedback.length,
                    feedbackContent: feedback,  // Adding the actual feedback content
                    timestamp: new Date().toISOString()
                });
                
                setMessage('Feedback sent successfully!');
                setFeedback(''); // Clear feedback input
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to send feedback.');
            }
        } catch (error) {
            console.error('Error sending feedback:', error);
            // Track feedback submission error
            trackEvent('feedback_submission_error', {
                username,
                error: error.message,
                feedbackContent: feedback || 'no feedback content found',  // Adding the actual feedback content
                timestamp: new Date().toISOString()
            });

            setMessage('Failed to send feedback. Please try again.');
        }
    };

    return (
        <div className="feedback-container">
            {/* Floating Feedback Button */}

            <button
                className="feedback-button"
                onClick={toggleFeedback}
                aria-label={isOpen ? 'Close Feedback Form' : 'Open Feedback Form'}
            >
                {isOpen ? <X size={20} /> : <MessageCircle size={20} />}
                <span className="feedback-text">Feedback</span>
            </button>

            {/* Feedback Form */}
            {isOpen && (
                <div className="feedback-form">
                    <textarea
                        placeholder="Your feedback..."
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                    />
                    <button onClick={handleSubmit}>Submit</button>
                    {message && <p className="feedback-message">{message}</p>}
                </div>
            )}
        </div>
    );
};

export default FeedbackButton;