import axios from 'axios';
import { isTokenExpired } from './utils/jwt';

// Retrieve the token from localStorage
const getToken = () => {
    const token = localStorage.getItem('token');
    if (!token || isTokenExpired(token)) {
        // Redirect to the landing page if token is expired or missing
        window.location.href = '/';
        return null;
    }
    return token;
};

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'https://proto-backend-d2c1638486b9.herokuapp.com'
});

// Add request interceptor to include token
api.interceptors.request.use(config => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// Add response interceptor for debugging
api.interceptors.response.use(
    response => {
        console.log('Response received:', response.status, response.data);
        return response;
    },
    error => {
        if (error.response?.status === 401) {
            // Token expired or invalid, redirect to landing page
            console.log('Token expired or invalid, redirecting to landing page');
            localStorage.removeItem('token'); // Clear the token
            window.location.href = '/'; // Redirect to landing page
        }
        console.error('Response error:', error.response?.status, error.response?.data);
        return Promise.reject(error);
    }
);

// Profile routes
api.getUserProfile = () => api.get('/api/user-profile');
api.updateProfile = (profileData) => api.post('/api/update-profile', profileData);
api.updateProspectingProfile = (prospectingProfile) => api.post('/api/update-prospecting-profile', prospectingProfile);
api.markVisited = () => api.post('/api/mark-visited');
api.getUserFiles = () => api.get('/api/files');
api.getVoices = () => api.get('/api/voices');
api.generateSpeech = (text, voiceId) => api.post('/api/text-to-speech', { text, voiceId }, { responseType: 'blob' });

api.cloneVoice = (formData) => api.post('/api/clone-voice', formData, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});

api.saveVideos = async (formData) => {
    const maxChunkSize = 5 * 1024 * 1024; // 5MB chunks
    const file = formData.get('openingVideo') || formData.get('closingVideo');
    const fileType = formData.get('openingVideo') ? 'openingVideo' : 'closingVideo';
    const token = localStorage.getItem('token'); // Get token here

    if (!file) return;

    const totalChunks = Math.ceil(file.size / maxChunkSize);
    let uploadedUrl = null;

    for (let i = 0; i < totalChunks; i++) {
        const start = i * maxChunkSize;
        const end = Math.min(file.size, start + maxChunkSize);
        const chunk = file.slice(start, end);

        const chunkFormData = new FormData();
        chunkFormData.append('file', chunk);
        chunkFormData.append('fileType', fileType);
        chunkFormData.append('chunkIndex', i);
        chunkFormData.append('totalChunks', totalChunks);

        const response = await api.post('/api/save-videos/chunk', chunkFormData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            timeout: 10000
        });

        if (response.data.url) {
            uploadedUrl = response.data.url;
        }
    }

    return { data: { [`${fileType}Url`]: uploadedUrl } };
};

// LinkedIn prospects routes
api.scrapeLinkedInProfile = (url) => api.post('/api/scrape-linkedin-profile', { url });
api.getLinkedInProspects = () => api.get('/api/linkedin-prospects');
api.deleteLinkedInProspect = (prospectId) => api.delete(`/api/linkedin-prospects/${prospectId}`);
api.generatePitch = (prospectId) => api.post(`/api/generate-pitch/${prospectId}`);
api.updateProspectPitch = (prospectId, pitch) => api.patch(`/api/prospects/${prospectId}/pitch`, { pitch });

export default api;