import React, { useState, useEffect } from 'react';
import api from './api';
import { ADMIN_PASSWORD } from './config.js';

function FilesPage() {
    const [files, setFiles] = useState([]);
    const [password, setPassword] = useState('');
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        if (authenticated) {
            fetchFiles();
        }
    }, [authenticated]);

    const fetchFiles = async () => {
        try {
            const response = await api.get('/admin/files'); // Use the admin endpoint
            const sortedFiles = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setFiles(sortedFiles);
        } catch (error) {
            console.error('Error fetching files:', error);
        }
    };

    const updateStatus = async (id, status) => {
        try {
            const response = await api.patch(`/files/${id}`, { status });
            setFiles((prevFiles) =>
                prevFiles.map((file) =>
                    file._id === id ? { ...file, status: response.data.status } : file
                )
            );
        } catch (error) {
            console.error('Error updating file status:', error);
        }
    };

    const deleteFile = async (id) => {
        try {
            console.log(`Attempting to delete file: ${id}`);
            const response = await api.delete(`/admin/files/${id}`);  // Changed to admin endpoint
            
            if (response.status === 200) {
                console.log('Delete successful');
                setFiles((prevFiles) => prevFiles.filter((file) => file._id !== id));
                // setMessage('File deleted successfully');
            }
        } catch (error) {
            console.error('Error deleting file:', error);
            if (error.response?.status === 404) {
                // setMessage('File not found. It may have been already deleted.');
                // Refresh the files list to ensure it's up to date
                fetchFiles();
            } else {
                // setMessage('Error deleting file. Please try again.');
            }
        }
    };

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        if (password === ADMIN_PASSWORD) {
            setAuthenticated(true);
        } else {
            alert('Incorrect password');
        }
    };

    return (
        <div>
            {!authenticated ? (
                <div className="password-container">
                    <form onSubmit={handlePasswordSubmit} className="password-form">
                        <input
                            type="password"
                            placeholder="Enter password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="password-input"
                        />
                        <button type="submit" className="submit-button">Submit</button>
                </form>
                </div>
            ) : (
                <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
                    <h1>All Files</h1>
                    <ul>
                        {files.map((file) => (
                            <li key={file._id}
                                style={{
                                    marginBottom: '20px',
                                    background: 'white',
                                    padding: '15px',
                                    borderRadius: '8px',
                                    boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
                                }}>
                            <div>
                                <strong>File:</strong>{' '}
                                <a href={file.fileUrl} target="_blank" rel="noopener noreferrer">
                                    {file.fileName}
                                </a>
                            </div>
                            <div>
                                <strong>User:</strong> {file.username}
                            </div>
                            <div>
                                <strong>Status:</strong>{' '}
                                {file.status === 'processed' ? '✅ Processed' : '🕒 Processing'}
                            </div>
                            <div>
                                <strong>Uploaded On:</strong>{' '}
                                {new Date(file.createdAt).toLocaleString()} {/* Format date */}
                            </div>
                            <div>
                                <strong>Extracted Text:</strong>
                                <p>{file.extractedText || 'No text extracted'}</p>
                            </div>
                            <div>
                                <strong>Additional Context:</strong>
                                <p>{file.additionalContext || 'No additional context provided'}</p>
                            </div>
                            <div>
                                <button onClick={() => updateStatus(file._id, 'processed')} style={{ backgroundColor: '#E1F679', borderRadius:'5px' }}>
                                    Mark as Processed
                                </button>
                                <button onClick={() => updateStatus(file._id, 'processing')} style={{ marginLeft: '10px', borderRadius:'5px'}}>
                                    Mark as Processing
                                </button>
                                <button onClick={() => deleteFile(file._id)} style={{ marginLeft: '10px', borderRadius:'5px', color: 'white', backgroundColor:'#C8736C', fontWeight:'bold' }}>
                                        Delete
                                </button>
                            </div>
                        </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default FilesPage;