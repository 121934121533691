import React, { useState, useEffect } from 'react';
import api from '../api';
import './AdminDashboard.css';
import { ADMIN_PASSWORD } from '../config.js';

const AdminDashboard = () => {
    const [password, setPassword] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [data, setData] = useState({ userStats: [], queries: [] });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = async () => {
        setLoading(true);
        setError(null);
        try {
            console.log('Attempting to fetch data...');
            const response = await api.get('/api/queries');
            console.log('Fetched data:', response.data);
            setData(response.data);
        } catch (error) {
            console.error('Detailed error:', error.response || error);
            setError(error.response?.data?.error || error.message || 'Failed to load data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            fetchData();
        }
    }, [isAuthenticated]);

    const handleSubmit = (e) => {
        console.log(password);
        console.log('Expected password:', ADMIN_PASSWORD);
        e.preventDefault();
        if (password === ADMIN_PASSWORD) {
            setIsAuthenticated(true);
        } else {
            alert('Incorrect password');
        }
    };

    if (!isAuthenticated) {
        return (
            <div className="password-container">
                <form onSubmit={handleSubmit} className="password-form">
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter password"
                        className="password-input"
                    />
                    <button type="submit" className="submit-button">
                        Submit
                    </button>
                </form>
            </div>
        );
    }

    if (loading) return <div className="loading">Loading...</div>;
    if (error) return <div className="error">Error: {error}</div>;

    return (
        <div className="admin-dashboard">
            <h1>Admin Dashboard</h1>
            
            <section className="user-stats">
                <h2>User Statistics</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Documents</th>
                            <th>Queries</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.userStats?.map((user) => (
                            <tr key={user._id}>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>{user.documentCount}</td>
                                <td>{user.queryCount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>

            <section className="recent-queries">
                <h2>Recent Queries</h2>
                <div className="queries-list">
                    {data.queries?.map((query) => (
                        <div key={query._id} className="query-card">
                            <div className="query-header">
                                <span className="user-info">
                                    {query.userId?.name} ({query.userId?.email})
                                </span>
                                <span className="timestamp">
                                    {new Date(query.createdAt).toLocaleString()}
                                </span>
                            </div>
                            <div className="query-content">
                                <p className="question"><strong>Q:</strong> {query.question}</p>
                                <p className="answer"><strong>A:</strong> {query.answer}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default AdminDashboard; 