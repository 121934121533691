import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import FeedbackButton from './FeedbackButton';
import { Video, Play, Pause, RefreshCw, Mic, Square, Trash2, Check, AlertCircle } from 'react-feather';
import api from './api';
import { decodeToken, isTokenExpired } from './utils/jwt';
import { trackEvent } from './utils/amplitude';
import debounce from 'lodash/debounce';
import WaveSurfer from 'wavesurfer.js';
import './App.css';

// Recording Examples
const RECORDING_EXAMPLES = [
    // Introduction & Context
    "Hi, I noticed your impressive work at [Company] and wanted to reach out.",
    "I've been following [Company]'s growth in the [industry] space.",
    "Your recent post about [topic] really resonated with me.",
    "Your background in [field] caught my attention.",
    "I saw your team is expanding the [department/initiative] and thought I'd reach out.",

    // Value Proposition
    "We help companies like yours improve [metric] by [percentage].",
    "Our solution has helped similar businesses save [X] hours per week.",
    "We've helped other [job title]s increase their team's productivity by [percentage].",
    "Our platform typically reduces [pain point] by [metric].",
    "Companies similar to yours have seen [specific result] within [timeframe].",

    // Industry-Specific
    "The [industry] landscape is rapidly changing, especially regarding [trend].",
    "Many [job title]s we work with struggle with [common challenge].",
    "In today's [industry] environment, [pain point] is more crucial than ever.",
    "With recent changes in [regulation/technology], many companies are looking to [goal].",
    "We've noticed a growing trend in [industry] where [observation].",

    // Problem Recognition
    "Many teams face challenges with [common pain point].",
    "I understand how challenging [specific task] can be.",
    "One common issue we see is [problem description].",
    "Teams often struggle to balance [competing priority A] with [priority B].",
    "The complexity of [process] often leads to [negative outcome].",

    // Solution Overview
    "Our approach simplifies [complex process] through [method].",
    "We've developed a unique solution for [specific challenge].",
    "Our platform automates [manual process] while maintaining [important aspect].",
    "We combine [technology A] with [technology B] to achieve [outcome].",
    "Our solution integrates seamlessly with [common tools] to improve [process].",

    // Results & Benefits
    "Clients typically see ROI within [timeframe].",
    "Our average customer saves [amount] per month.",
    "Teams report [percentage] improvement in [metric] after implementation.",
    "We've helped companies reduce [cost/time] by up to [percentage].",
    "Users experience [benefit] while maintaining [important factor].",

    // Social Proof
    "Companies like [known brand] have achieved [result] using our solution.",
    "We recently helped a [industry] company overcome [challenge].",
    "One of our clients in [industry] reported [specific outcome].",
    "A similar [company type] saw [improvement] within [timeframe].",
    "We've worked with several teams in [industry] to improve [metric].",

    // Use Cases
    "This works particularly well for teams that need to [specific use case].",
    "It's especially useful when you're trying to [achieve goal].",
    "Many teams use this to streamline their [process].",
    "This solution is perfect for organizations looking to [objective].",
    "We've seen great results with teams focusing on [specific goal].",

    // Implementation & Support
    "The implementation process typically takes [timeframe].",
    "Our support team provides [type of support] throughout the process.",
    "We offer comprehensive training for [feature/process].",
    "The onboarding process is designed to minimize [common concern].",
    "Our team handles all aspects of [technical process].",

    // Future-Focused
    "We're constantly developing new features for [emerging need].",
    "Our roadmap includes exciting developments in [area].",
    "We're preparing for [industry trend] with [feature/capability].",
    "Our solution adapts to [changing circumstance] automatically.",
    "We're investing heavily in [future technology/capability]."
];

function Dashboard() {

    // User info
    const [userName, setUserName] = useState('');
    const [userId, setUserId] = useState(null);

    // Profile info
    const [profileComplete, setProfileComplete] = useState(false);
    const [userProfile, setUserProfile] = useState({
        currentSituation: '',
        shortTermGoals: '',
        longTermGoals: ''
    });
    const [prospectingProfile, setProspectingProfile] = useState({
        currentRole: '',
        companyPurpose: '',
        targetAudience: ''
    });
    const [prospectingProfileSaveStatus, setProspectingProfileSaveStatus] = useState('');
    const [userInfo, setUserInfo] = useState({
        picture: '',
        name: ''
    });
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [hasVisited, setHasVisited] = useState(true);

    // Navigation (for redirecting to landing page)
    const navigate = useNavigate();

    // Video imports
    const [openingVideo, setOpeningVideo] = useState(null);
    const [closingVideo, setClosingVideo] = useState(null);
    const [openingVideoUrl, setOpeningVideoUrl] = useState('');
    const [closingVideoUrl, setClosingVideoUrl] = useState('');
    const [uploadError, setUploadError] = useState('');

    // Voice generation
    const [isRecording, setIsRecording] = useState(false);
    const [recordings, setRecordings] = useState([]);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [isGeneratingVoice, setIsGeneratingVoice] = useState(false);
    const [audioUrl, setAudioUrl] = useState('');
    const [isPlayingVoice, setIsPlayingVoice] = useState(false);
    const audioRef = useRef(null);

    // LinkedIn prospects states
    const [prospects, setProspects] = useState([]);
    const [newProspectUrl, setNewProspectUrl] = useState('');
    const [isScrapingProfile, setIsScrapingProfile] = useState(false);
    const [processingUrls, setProcessingUrls] = useState(new Set());
    const [scrapingError, setScrapingError] = useState('');
    const [isDeleting, setIsDeleting] = useState(new Set()); // Track deleting state

    // Pitch generation
    const [generatingPitch, setGeneratingPitch] = useState(new Set());
    const [savingPitch, setSavingPitch] = useState(new Set());

    // Speech generation
    const [generatingSpeech, setGeneratingSpeech] = useState(new Set());
    const [audioUrls, setAudioUrls] = useState({}); // Store audio URLs by prospect ID

    // Video generation
    const [generatingVideo, setGeneratingVideo] = useState(new Set());

    // Copy to clipboard
    const [copiedLinks, setCopiedLinks] = useState(new Set());

    // Waveform visualization states
    const waveformRefs = useRef({});
    const wavesurferInstances = useRef({});

    // Recording Examples
    const [showExamples, setShowExamples] = useState(false);
    const [currentExample, setCurrentExample] = useState(0);
    const [displayedExamples, setDisplayedExamples] = useState([]);

    // User voice
    const [userVoiceId, setUserVoiceId] = useState(null);

    // Fetch user info on component mount
    useEffect(() => {
        const initializeUser = async () => {
            const params = new URLSearchParams(window.location.search);
            const tokenFromUrl = params.get('token');
            let token = tokenFromUrl || localStorage.getItem('token');

            console.log("params:", params);
            console.log("tokenFromUrl:", tokenFromUrl);
            console.log('Token from localStorage:', localStorage.getItem('token'));
            console.log("token:", token);

            if (tokenFromUrl) {
                // Save the token to local storage
                localStorage.setItem('token', tokenFromUrl);
                console.log('Token saved to localStorage:', tokenFromUrl);
                
                // Clean up the URL to remove the token
                window.history.replaceState({}, document.title, window.location.pathname);
            }

            // Validate the token
            if (!token || isTokenExpired(token)) {
                console.log('Token is invalid or expired. Redirecting to landing page.');
                navigate('/');
                return;
            }

            // Decode the token to get the user ID and fetch files
            const decoded = decodeToken(token);
            console.log('decoded Token:', decoded);
            console.log('decoded username:', decoded.name);
            console.log('decoded ID:', decoded.userId);

            if (decoded) {
                setUserId(decoded.userId); // Set the user ID
                setUserName(decoded.name); // Extract the name from the token
                setUserInfo(prev => ({ ...prev, name: decoded.name }));

                try {
                    // Fetch profile
                    const profileResponse = await api.getUserProfile();
                    console.log('Profile response:', profileResponse.data);
                    
                    if (profileResponse.data) {
                        setUserProfile(profileResponse.data.profile);
                        setProspectingProfile(profileResponse.data.prospectingProfile || {
                            currentRole: '',
                            companyPurpose: '',
                            targetAudience: ''
                        });
                        setProfileComplete(profileResponse.data.profileComplete);
                        setHasVisited(profileResponse.data.hasVisited);
                        setUserInfo(prev => ({
                            ...prev,
                            picture: profileResponse.data.picture,
                            name: profileResponse.data.name || decoded.name
                        }));
                        
                        if (!profileResponse.data.hasVisited) {
                            setShowProfileModal(true);
                            await api.markVisited();
                        }
                    }

                    // Fetch User's LinkedIn prospects
                    const prospectsResponse = await api.getLinkedInProspects();
                    setProspects(prospectsResponse.data);
                } catch (error) {
                    console.error('Error initializing:', error);
                    if (error.response?.status === 401) {
                        navigate('/');
                    }
                }
            }
        };

        initializeUser();
    }, [navigate]);

    // Handle profile updates
    const handleProfileSave = async () => {
        try {
            trackEvent('profile_update_started', {
                userId,
                fieldsUpdated: Object.keys(userProfile).filter(key => userProfile[key].trim() !== '')
            });

            const response = await api.updateProfile(userProfile);

            trackEvent('profile_update_successful', {
                userId,
                profileComplete: response.data.profileComplete
            });

            setProfileComplete(response.data.profileComplete);
            setShowProfileModal(false);
        } catch (error) {
            trackEvent('profile_update_error', {
                error: error.message,
                userId
            });

            console.error('Error saving profile:', error);
        }
    };

    // Handle prospecting profile updates
    const handleProspectingProfileSave = async () => {
        try {
            setProspectingProfileSaveStatus('saving');

            trackEvent('prospecting_profile_update_started', {
                userId,
                fieldsUpdated: Object.keys(prospectingProfile).filter(key => prospectingProfile[key].trim() !== '')
            });

            const response = await api.updateProspectingProfile(prospectingProfile);

            trackEvent('prospecting_profile_update_successful', {
                userId,
                prospectingProfileComplete: response.data.prospectingProfileComplete
            });

            setProspectingProfileSaveStatus('saved');

            // Reset the save status after 3 seconds
            setTimeout(() => {
                setProspectingProfileSaveStatus('');
            }, 2000);

            // You might want to show a success message here
        } catch (error) {
            trackEvent('prospecting_profile_update_error', {
                error: error.message,
                userId
            });

            console.error('Error saving prospecting profile:', error);
            setProspectingProfileSaveStatus('error');

            // Reset the save status after 3 seconds
            setTimeout(() => {
                setProspectingProfileSaveStatus('');
            }, 2000);
        }
    };

    // Profile Modal Component
    const ProfileModal = () => (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000
        }}>
            <div style={{
                backgroundColor: 'white',
                padding: '30px',
                borderRadius: '10px',
                width: '90%',
                maxWidth: '600px',
                maxHeight: '90vh',
                overflowY: 'auto'
            }}>
                <h2>Hi {(userInfo.name || userName)?.split(' ')[0]}!</h2>
                <h2>Tell us more about you</h2>
                <p style={{ color: '#666', marginBottom: '20px' }}>
                    This information helps us provide better personalized assistance
                </p>
                {!hasVisited && (
                    <p style={{ color: '#007bff', marginBottom: '20px' }}>
                        You can change it whenever.
                    </p>
                )}

                <div style={{ marginBottom: '20px' }}>
                    <label style={{ display: 'block', marginBottom: '5px' }}>
                        Current Situation
                    </label>
                    <textarea
                        value={userProfile.currentSituation}
                        onChange={(e) => setUserProfile({
                            ...userProfile,
                            currentSituation: e.target.value
                        })}
                        placeholder="Your current job, skills, experience..."
                        style={{
                            width: '100%',
                            padding: '10px',
                            borderRadius: '5px',
                            border: '1px solid #dfe1e5',
                            minHeight: '100px'
                        }}
                    />
                </div>

                <div style={{ marginBottom: '20px' }}>
                    <label style={{ display: 'block', marginBottom: '5px' }}>
                        Short Term Goals
                    </label>
                    <textarea
                        value={userProfile.shortTermGoals}
                        onChange={(e) => setUserProfile({
                            ...userProfile,
                            shortTermGoals: e.target.value
                        })}
                        placeholder="Goals for the next 3-6 months..."
                        style={{
                            width: '100%',
                            padding: '10px',
                            borderRadius: '5px',
                            border: '1px solid #dfe1e5',
                            minHeight: '100px'
                        }}
                    />
                </div>

                <div style={{ marginBottom: '20px' }}>
                    <label style={{ display: 'block', marginBottom: '5px' }}>
                        Long Term Goals
                    </label>
                    <textarea
                        value={userProfile.longTermGoals}
                        onChange={(e) => setUserProfile({
                            ...userProfile,
                            longTermGoals: e.target.value
                        })}
                        placeholder="Where do you see yourself in 1-5 years..."
                        style={{
                            width: '100%',
                            padding: '10px',
                            borderRadius: '5px',
                            border: '1px solid #dfe1e5',
                            minHeight: '100px'
                        }}
                    />
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                    <button
                        onClick={() => setShowProfileModal(false)}
                        style={{
                            padding: '8px 16px',
                            borderRadius: '5px',
                            border: '1px solid #dfe1e5',
                            backgroundColor: 'white',
                            cursor: 'pointer'
                        }}
                    >
                        Close
                    </button>
                    <button
                        onClick={handleProfileSave}
                        className="main-button"
                    >
                        Save Profile
                    </button>
                </div>
            </div>
        </div>
    );

    // Opening video dropzone
    const onDropOpening = useCallback(async (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file && file.type.startsWith('video/')) {
            setOpeningVideo(file);
            
            // Automatically save the video when it's dropped
            const formData = new FormData();
            formData.append('openingVideo', file);
            
            try {
                const response = await api.saveVideos(formData);
                setOpeningVideoUrl(response.data.openingVideoUrl);
                setUploadError('');
            } catch (error) {
                console.error('Error saving opening video:', error);
                setUploadError('Failed to save opening video');
            }
        } else {
            setUploadError('Please upload a valid video file');
        }
    }, []);

    // Closing video dropzone
    const onDropClosing = useCallback(async (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file && file.type.startsWith('video/')) {
            setClosingVideo(file);
            
            // Automatically save the video when it's dropped
            const formData = new FormData();
            formData.append('closingVideo', file);
            
            try {
                const response = await api.saveVideos(formData);
                setClosingVideoUrl(response.data.closingVideoUrl);
                setUploadError('');
            } catch (error) {
                console.error('Error saving closing video:', error);
                setUploadError('Failed to save closing video');
            }
        } else {
            setUploadError('Please upload a valid video file');
        }
    }, []);

    // Configure dropzones
    const {
        getRootProps: getOpeningRootProps,
        getInputProps: getOpeningInputProps,
        isDragActive: isOpeningDragActive
    } = useDropzone({
        onDrop: onDropOpening,
        accept: {
            'video/*': ['.mp4', '.mov', '.avi', '.wmv']
        },
        maxFiles: 1
    });

    const {
        getRootProps: getClosingRootProps,
        getInputProps: getClosingInputProps,
        isDragActive: isClosingDragActive
    } = useDropzone({
        onDrop: onDropClosing,
        accept: {
            'video/*': ['.mp4', '.mov', '.avi', '.wmv']
        },
        maxFiles: 1
    });

    // Function to remove videos
    const removeVideo = (type) => {
        if (type === 'opening') {
            setOpeningVideo(null);
            setOpeningVideoUrl(null);
        } else {
            setClosingVideo(null);
            setClosingVideoUrl(null);
        }
    };

    // Initialize voice recorder
    const initializeRecorder = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const recorder = new MediaRecorder(stream);
            
            recorder.ondataavailable = (e) => {
                if (e.data.size > 0) {
                    setRecordings(prev => [...prev, e.data]);
                }
            };

            setMediaRecorder(recorder);
            return recorder;
        } catch (error) {
            console.error('Error accessing microphone:', error);
            return null;
        }
    };

    // Handle recording controls
    const startRecording = async () => {
        if (!mediaRecorder) {
            // Initialize recorder when user clicks start
            const recorder = await initializeRecorder();
            if (recorder) {
                recorder.start();
                setIsRecording(true);
            }
        } else if (mediaRecorder.state === 'inactive') {
            mediaRecorder.start();
            setIsRecording(true);
        }
    };

    const stopRecording = () => {
        if (mediaRecorder && mediaRecorder.state === 'recording') {
            mediaRecorder.stop();
            setIsRecording(false);
        }
    };

    // Handle voice cloning
    const handleVoiceCloning = async () => {
        try {
            setIsGeneratingVoice(true);
            
            // Create a form with the recordings
            const formData = new FormData();
            recordings.forEach((recording, index) => {
                formData.append('recordings', new File([recording], `sample_${index}.wav`));
            });

            // Clone the voice using ElevenLabs API
            const response = await api.cloneVoice(formData);
            setUserVoiceId(response.data.voice_id);
            setIsGeneratingVoice(false);

            // Clear recordings after successful cloning
            setRecordings([]);
        } catch (error) {
            console.error('Error cloning voice:', error);
            setIsGeneratingVoice(false);
        }
    };

    // Test cloned voice
    const testClonedVoice = async () => {
        if (!userVoiceId) {
            alert('Please clone your voice first');
            return;
        }

        try {
            setIsGeneratingVoice(true);
            const response = await api.generateSpeech(
                "Hello! This is my cloned voice. How does it sound?",
                userVoiceId
            );
            const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
            const url = URL.createObjectURL(audioBlob);
            setAudioUrl(url);
            setIsGeneratingVoice(false);
        } catch (error) {
            console.error('Error testing cloned voice:', error);
            setIsGeneratingVoice(false);
        }
    };

    // Handle LinkedIn URL submission
    const handleAddProspect = async (e) => {
        e.preventDefault();
        
        // Basic URL validation
        if (!newProspectUrl.includes('linkedin.com/')) {
            setScrapingError('Please enter a valid LinkedIn URL');
            return;
        }

        // Check if URL already exists
        if (prospects.some(p => p.linkedinUrl === newProspectUrl)) {
            setScrapingError('This profile has already been added');
            return;
        }

        setScrapingError('');
        setIsScrapingProfile(true);
        setProcessingUrls(prev => new Set(prev).add(newProspectUrl));

        try {
            const response = await api.scrapeLinkedInProfile(newProspectUrl);
            const newProspect = response.data;
            
            setProspects(prev => [...prev, {
                id: `prospect-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
                linkedinUrl: newProspectUrl,
                firstName: newProspect.firstName,
                lastName: newProspect.lastName,
                company: newProspect.company,
                jobTitle: newProspect.jobTitle,
                dateAdded: new Date().toISOString(),
                sent: false,
                customPitch: '',
                videoUrl: '',
                viewCount: 0
            }]);
            
            setNewProspectUrl('');
        } catch (error) {
            console.error('Error scraping profile:', error);
            setScrapingError(
                error.response?.data?.message || 
                'Failed to fetch LinkedIn profile. Please check the URL and try again.'
            );
        } finally {
            setIsScrapingProfile(false);
            setProcessingUrls(prev => {
                const newSet = new Set(prev);
                newSet.delete(newProspectUrl);
                return newSet;
            });
        }
    };

    // Delete prospect
    const handleDeleteProspect = async (prospectId) => {
        if (window.confirm('Are you sure you want to delete this prospect?')) {
            setIsDeleting(prev => new Set(prev).add(prospectId));
            
            try {
                await api.deleteProspect(prospectId);
                setProspects(prev => prev.filter(p => p.id !== prospectId));
                
                // Show success message (if you have a notification system)
                // showNotification('Prospect deleted successfully');
            } catch (error) {
                console.error('Error deleting prospect:', error);
                // Show error message
                // showNotification('Failed to delete prospect', 'error');
            } finally {
                setIsDeleting(prev => {
                    const newSet = new Set(prev);
                    newSet.delete(prospectId);
                    return newSet;
                });
            }
        }
    };

    // Generate pitch
    const handleGeneratePitch = async (prospectId) => {
        setGeneratingPitch(prev => new Set(prev).add(prospectId));
        
        try {
            const response = await api.generatePitch(prospectId);
            
            setProspects(prev => prev.map(p => 
                p.id === prospectId
                    ? { ...p, customPitch: response.data.pitch }
                    : p
            ));

        } catch (error) {
            console.error('Error generating pitch:', error);
            // Show error message to user
        } finally {
            setGeneratingPitch(prev => {
                const newSet = new Set(prev);
                newSet.delete(prospectId);
                return newSet;
            });
        }
    };

    // Debounce pitch saving
    const debouncedSavePitch = useCallback(
        async (prospectId, pitch) => {
            try {
                setSavingPitch(prev => new Set(prev).add(prospectId));
                await api.updateProspectPitch(prospectId, pitch);
            } catch (error) {
                console.error('Error saving pitch:', error);
            } finally {
                setSavingPitch(prev => {
                    const newSet = new Set(prev);
                    newSet.delete(prospectId);
                    return newSet;
                });
            }
        },
        [setSavingPitch] // Add the state setter as a dependency
    );

    const debouncedSavePitchWithDebounce = useMemo(
        () => debounce(debouncedSavePitch, 1000),
        [debouncedSavePitch]
    );

    // Handle pitch change
    const handlePitchChange = (prospectId, newPitch) => {
        setProspects(prev => 
            prev.map(p => 
                p.id === prospectId 
                    ? { ...p, customPitch: newPitch }
                    : p
            )
        );
        debouncedSavePitchWithDebounce(prospectId, newPitch);
    };

    // Speech generation & video loading
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await api.getUserProfile();
                const userData = response.data;
                setUserVoiceId(userData.voiceId);

                // Set video URLs if they exist
                if (userData.openingVideoUrl) {
                    setOpeningVideoUrl(userData.openingVideoUrl);
                }
                if (userData.closingVideoUrl) {
                    setClosingVideoUrl(userData.closingVideoUrl);
                }

                // Fetch prospects
                const prospectsResponse = await api.getLinkedInProspects();
                setProspects(prospectsResponse.data);

            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };
    
        fetchUserData();
    }, []);

    // Clean up resources when component unmounts
    useEffect(() => {
        return () => {
            // Clean up audio URLs
            Object.values(audioUrls).forEach(url => {
                URL.revokeObjectURL(url);
            });

            // Stop media recorder if active
            if (mediaRecorder && mediaRecorder.state === 'recording') {
                mediaRecorder.stop();
            }

            // Clean up media stream
            if (mediaRecorder) {
                mediaRecorder.stream.getTracks().forEach(track => track.stop());
            }
        };
    }, [audioUrls, mediaRecorder]);

    const handleGenerateSpeech = async (prospectId, pitch) => {
        if (!pitch) {
            // Optional: show error message
            return;
        }

        if (!userVoiceId) {
            alert('Please clone your voice first before generating speech');
            return;
        }

        setGeneratingSpeech(prev => new Set(prev).add(prospectId));
        
        try {
            const response = await api.generateSpeech(pitch, userVoiceId); // userVoiceId should come from user profile
            const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
            const url = URL.createObjectURL(audioBlob);
            
            setAudioUrls(prev => ({
                ...prev,
                [prospectId]: url
            }));

        } catch (error) {
            console.error('Error generating speech:', error);
            // Optional: show error message
            alert('Failed to generate speech. Please try again.');
        } finally {
            setGeneratingSpeech(prev => {
                const newSet = new Set(prev);
                newSet.delete(prospectId);
                return newSet;
            });
        }
    };

    // Video generation
    const handleGenerateVideo = async (prospectId) => {
        const prospect = prospects.find(p => p.id === prospectId);
        
        // Validate required elements
        if (!prospect) {
            console.error('Prospect not found');
            return;
        }
        
        if (!openingVideoUrl || !closingVideoUrl) {
            alert('Please upload both opening and closing videos first');
            return;
        }

        if (!audioUrls[prospectId]) {
            alert('Please generate speech first');
            return;
        }

        setGeneratingVideo(prev => new Set(prev).add(prospectId));
        
        try {
            const response = await api.generateVideo({
                prospectId,
                openingVideo: openingVideoUrl,
                closingVideo: closingVideoUrl,
                audioUrl: audioUrls[prospectId],
                prospect: {
                    firstName: prospect.firstName,
                    lastName: prospect.lastName,
                    company: prospect.company,
                    jobTitle: prospect.jobTitle
                }
            });
            
            // Update prospect with video data
            setProspects(prev => prev.map(p => 
                p.id === prospectId
                    ? { 
                        ...p, 
                        videoData: {
                            ...p.videoData,
                            pageUrl: response.data.pageUrl,
                            videoUrl: response.data.videoUrl,
                            viewCount: 0
                        }
                    }
                    : p
            ));

        } catch (error) {
            console.error('Error generating video:', error);
            // Optional: show error notification
        } finally {
            setGeneratingVideo(prev => {
                const newSet = new Set(prev);
                newSet.delete(prospectId);
                return newSet;
            });
        }
    };

    // Copy video link
    const handleCopyLink = async (prospectId) => {
        const prospect = prospects.find(p => p.id === prospectId);
        if (!prospect?.videoUrl) return;

        try {
            await navigator.clipboard.writeText(prospect.videoUrl);
            setCopiedLinks(prev => new Set(prev).add(prospectId));
            
            // Reset "Copied!" state after 2 seconds
            setTimeout(() => {
                setCopiedLinks(prev => {
                    const newSet = new Set(prev);
                    newSet.delete(prospectId);
                    return newSet;
                });
            }, 2000);
        } catch (error) {
            console.error('Error copying to clipboard:', error);
        }
    };

    // Clean up audio URLs when component unmounts
    useEffect(() => {
        return () => {
            Object.values(audioUrls).forEach(url => {
                URL.revokeObjectURL(url);
            });
        };
    }, [audioUrls]);

    // Function to create waveform
    const createWaveform = (recordingUrl, index) => {
        if (wavesurferInstances.current[index]) {
            wavesurferInstances.current[index].destroy();
        }

        const wavesurfer = WaveSurfer.create({
            container: waveformRefs.current[index],
            waveColor: '#AB5898',
            progressColor: '#5C4BD3',
            cursorColor: '#000000',
            barWidth: 2,
            barGap: 1,
            height: 40,
            barRadius: 3,
            normalize: true,
            responsive: true,
            interact: true,
        });

        wavesurfer.load(recordingUrl);
        wavesurferInstances.current[index] = wavesurfer;

        return wavesurfer;
    };

    // Clean up wavesurfer instances on unmount
    useEffect(() => {
        // Store the current instances in a variable inside the effect
        const currentInstances = wavesurferInstances.current;
    
        // Cleanup function
        return () => {
            Object.values(currentInstances).forEach(instance => {
                if (instance) {
                    instance.destroy();
                }
            });
        };
    }, []); // Empty dependency array since we only want this to run on mount/unmount

    // Initialize random examples when showing them
    useEffect(() => {
        if (showExamples) {
            // Shuffle array and take first 10 examples
            const shuffled = [...RECORDING_EXAMPLES]
                .sort(() => Math.random() - 0.5)
                .slice(0, 10);
            setDisplayedExamples(shuffled);
            setCurrentExample(0);
        }
    }, [showExamples]);

    // Rotate through selected examples
    useEffect(() => {
        let interval;
        if (showExamples && displayedExamples.length > 0) {
            interval = setInterval(() => {
                setCurrentExample((prev) => (prev + 1) % displayedExamples.length);
            }, 5000); // Change example every 5 seconds
        }
        return () => clearInterval(interval);
    }, [showExamples, displayedExamples]);

    return (
        <div
            style={{ 
                minHeight: '100vh', 
                padding: '20px',
                backgroundColor: '#FBFBFB',
                boxSizing: 'border-box'
            }}
        >
            {/* Header */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '30px' }}>
                <h1>Merra.ai</h1>
                <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                    <button 
                        className="profile-button"
                        onClick={() => setShowProfileModal(true)}
                        style={{
                            width: '32px',
                            height: '32px',
                            borderRadius: '50%',
                            border: 'none',
                            cursor: 'pointer',
                            position: 'relative',
                            padding: 0,
                            backgroundColor: userInfo.picture ? 'transparent' : '#007bff',
                        }}
                    >
                        {userInfo.picture ? (
                            <img 
                                src={userInfo.picture} 
                                alt="Profile" 
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderRadius: '50%'
                                }}
                            />
                        ) : (
                            <i className="fas fa-user" style={{ color: 'white' }}></i>
                        )}

                        {/* Notification Dot */}
                        {!profileComplete && (
                            <span style={{
                                position: 'absolute',
                                top: '-5px',
                                right: '-5px',
                                width: '12px',
                                height: '12px',
                                backgroundColor: '#ff4444',
                                borderRadius: '50%',
                                border: '2px solid white',
                            }} />
                        )}
                    </button>
                </div>
            </div>

            {/* Profile Modal */}
            {showProfileModal && <ProfileModal />}

            {/* Greeting */}
            <div style={{ textAlign: 'center', marginBottom: '30px' }}>
                <h2>Hello {userName.split(' ')[0]}!</h2>
                <h2 className="gradient-text" style={{justifySelf:"center", fontSize:'20px'}}>
                    Feeling limitless today?
                </h2>
            </div>

            {/* Main Content */}
            <div style={{ display: 'flex', gap: '20px' }}>
                {/* Video Upload Section */}
                <div
                    style={{
                        flex: 1,
                        padding: '20px',
                        borderRadius: '10px',
                        border: '1px solid #dfe1e5',
                        backgroundColor: 'white',
                        overflowY: 'auto',
                    }}
                >
                <h3>Upload Videos</h3>
                <p style={{fontSize:'14px'}}>
                    Upload an opening and closing video. They will be used to generate personalized messages.
                </p>
                    
                    {/* Opening Video Dropzone */}
                    <div style={{ marginBottom: '30px' }}>
                        <label>My Opening Video:</label>
                        <div
                            {...getOpeningRootProps()}
                            style={{
                                border: '2px dashed #007bff',
                                marginTop: '10px',
                                borderRadius: '8px',
                                padding: '20px',
                                textAlign: 'center',
                                cursor: 'pointer',
                                backgroundColor: isOpeningDragActive ? '#f0f8ff' : 'white',
                                position: 'relative',
                                // minHeight: '100px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <input {...getOpeningInputProps()} />
                            {openingVideo ? (
                                <div style={{ width: '100%' }}>
                                    <video
                                        style={{ maxWidth: '100%', maxHeight: '150px' }}
                                        controls
                                    >
                                        <source src={URL.createObjectURL(openingVideo)} type={openingVideo.type} />
                                        Your browser does not support the video tag.
                                    </video>
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            removeVideo('opening');
                                        }}
                                        style={{
                                            position: 'absolute',
                                            top: '5px',
                                            right: '5px',
                                            background: 'white',
                                            border: '1px solid #ddd',
                                            borderRadius: '50%',
                                            padding: '5px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <Trash2 size={16} />
                                    </button>
                                </div>
                            ) : (
                                <div>
                                    <Video size={20} style={{ marginBottom: '10px' }} />
                                    <p style={{ margin: 0, fontSize:'14px' }}>Drop opening video here or click to select</p>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Closing Video Dropzone */}
                    <div>
                        <label>My Closing Video:</label>
                        <div
                            {...getClosingRootProps()}
                            style={{
                                border: '2px dashed #007bff',
                                marginTop: '10px',
                                borderRadius: '8px',
                                padding: '20px',
                                textAlign: 'center',
                                cursor: 'pointer',
                                backgroundColor: isClosingDragActive ? '#f0f8ff' : 'white',
                                position: 'relative',
                                // minHeight: '100px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <input {...getClosingInputProps()} />
                            {closingVideo ? (
                                <div style={{ width: '100%' }}>
                                    <video
                                        style={{ maxWidth: '100%', maxHeight: '150px' }}
                                        controls
                                    >
                                        <source src={URL.createObjectURL(closingVideo)} type={closingVideo.type} />
                                        Your browser does not support the video tag.
                                    </video>
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            removeVideo('closing');
                                        }}
                                        style={{
                                            position: 'absolute',
                                            top: '5px',
                                            right: '5px',
                                            background: 'white',
                                            border: '1px solid #ddd',
                                            borderRadius: '50%',
                                            padding: '5px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <Trash2 size={16} />
                                    </button>
                                </div>
                            ) : (
                                <div>
                                    <Video size={20} style={{ marginBottom: '10px' }} />
                                    <p style={{ margin: 0, fontSize:'14px' }}>Drop closing video here or click to select</p>
                                </div>
                            )}
                        </div>
                    </div>

                    {uploadError && (
                        <p style={{ color: 'red', marginTop: '10px' }}>{uploadError}</p>
                    )}
                </div>

                {/* Company/Role Description */}
                <div
                    style={{
                        flex: 1,
                        padding: '20px',
                        borderRadius: '10px',
                        border: '1px solid #dfe1e5',
                        backgroundColor: 'white',
                        overflowY: 'auto',
                    }}
                >
                    <h3>Your Prospecting Profile</h3>
                    {/* <div style={{ marginBottom: '20px' }}>
                        <label style={{ display: 'block', marginBottom: '5px' }}>
                            Current Role & Experience
                        </label>
                        <textarea
                            value={prospectingProfile.currentRole}
                            onChange={(e) => setProspectingProfile({
                                ...prospectingProfile,
                                currentRole: e.target.value
                            })}
                            placeholder="Describe your current role, company, and experience..."
                            style={{
                                width: '100%',
                                padding: '10px',
                                borderRadius: '5px',
                                border: '1px solid #dfe1e5',
                                minHeight: '100px'
                            }}
                        />
                    </div> */}

                    {/* <div style={{ marginBottom: '20px' }}>
                        <label style={{ display: 'block', marginBottom: '5px' }}>
                            Company Purpose & Goals
                        </label>
                        <textarea
                            value={prospectingProfile.companyPurpose}
                            onChange={(e) => setProspectingProfile({
                                ...prospectingProfile,
                                companyPurpose: e.target.value
                            })}
                            placeholder="What does your company do? What are your goals?"
                            style={{
                                width: '100%',
                                padding: '10px',
                                borderRadius: '5px',
                                border: '1px solid #dfe1e5',
                                minHeight: '100px'
                            }}
                        />
                    </div> */}

                    <div style={{ marginBottom: '20px' }}>
                        <label style={{ display: 'block', marginBottom: '5px' }}>
                            Target Audience & Value Proposition:
                        </label>
                        <textarea
                            value={prospectingProfile.targetAudience}
                            onChange={(e) => setProspectingProfile({
                                ...prospectingProfile,
                                targetAudience: e.target.value
                            })}
                            placeholder="Who are your ideal customers? What value do you provide?"
                            style={{
                                width: '100%',
                                padding: '10px',
                                borderRadius: '5px',
                                border: '1px solid #dfe1e5',
                                minHeight: '100px',
                                boxSizing: 'border-box'
                            }}
                        />
                    </div>

                    <button
                        onClick={handleProspectingProfileSave}
                        className="main-button"
                        style={{ 
                            width: '100%',
                            position: 'relative',
                            backgroundColor: prospectingProfileSaveStatus === 'saved' ? '#ADEBB3' : // Green for success
                                            prospectingProfileSaveStatus === 'error' ? '#f44336' : // Red for error
                                            '#e1f679' //'#1a73e8' // Default blue
                        }}
                        disabled={prospectingProfileSaveStatus === 'saving'}
                    >
                        {prospectingProfileSaveStatus === 'saving' ? (
                            <>
                                <RefreshCw className="spin" size={16} style={{ marginRight: '8px' }} />
                                Saving...
                            </>
                        ) : prospectingProfileSaveStatus === 'saved' ? (
                            <>
                                <Check size={16} style={{ marginRight: '8px' }} />
                                Saved!
                            </>
                        ) : prospectingProfileSaveStatus === 'error' ? (
                            <>
                                <AlertCircle size={16} style={{ marginRight: '8px' }} />
                                Error Saving
                            </>
                        ) : (
                            'Save Prospecting Profile'
                        )}
                    </button>
                </div>

                {/* Voice Cloning Interface */}
                <div
                    style={{
                        flex: 1,
                        padding: '20px',
                        borderRadius: '10px',
                        border: '1px solid #dfe1e5',
                        backgroundColor: 'white',
                        overflowY: 'auto',
                    }}
                >
                    <h3>Voice Cloning</h3>
                    <div style={{ marginBottom: '20px' }}>
                        <div style={{ 
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between', 
                            alignItems: 'center',
                            marginBottom: '10px' 
                        }}>
                            <p style={{ fontSize:'14px', alignSelf:'flex-start', marginTop:'0px'    }}>
                                Record samples of your voice to create a digital clone
                            </p>
                            <p style={{ marginBottom: '10px', marginTop:'0px' }}>
                                Record 3-5 samples of your voice for the best results. 
                                Each recording should be 10-30 seconds long.
                            </p>
                            <button
                                onClick={() => setShowExamples(!showExamples)}
                                style={{
                                    padding: '2px 12px',
                                    backgroundColor: 'transparent',
                                    color: '#1a73e8',
                                    border: '1px solid #1a73e8',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    whiteSpace: 'nowrap',
                                    marginLeft: '16px',
                                }}
                            >
                                {showExamples ? 'Hide Sentence Inspiration' : 'Show Sentence Inspiration'}
                            </button>
                        </div>

                        {/* Example Sentences */}
                        {showExamples && (
                            <div style={{
                                padding: '12px',
                                backgroundColor: '#f8f9fa',
                                borderRadius: '4px',
                                marginBottom: '16px',
                                border: '1px solid #dfe1e5',
                                position: 'relative',
                                overflow: 'hidden',
                                height: '60px' // Fixed height to prevent layout shifts
                            }}>
                                {displayedExamples.map((example, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '12px',
                                            right: '12px',
                                            transform: `translateY(-50%) translateY(${(index - currentExample) * 60}px)`,
                                            opacity: index === currentExample ? 1 : 0,
                                            transition: 'all 0.5s ease-in-out',
                                            textAlign: 'center',
                                            color: '#666'
                                        }}
                                    >
                                        "{example}"
                                    </div>
                                ))}
                            </div>
                        )}

                        {/* Progress Bar */}
                        <div style={{ marginBottom: '20px' }}>
                            <div style={{ 
                                display: 'flex', 
                                justifyContent: 'space-between',
                                marginBottom: '8px',
                                fontSize: '0.9em',
                                color: '#666'
                            }}>
                                <span>Recording Progress</span>
                                <span>{recordings.length}/5 samples</span>
                            </div>
                            <div style={{
                                width: '100%',
                                height: '8px',
                                backgroundColor: '#f0f0f0',
                                borderRadius: '4px',
                                overflow: 'hidden'
                            }}>
                                <div style={{
                                    width: `${Math.min((recordings.length / 5) * 100, 100)}%`,
                                    height: '100%',
                                    background: 'linear-gradient(to right, #000 20%, #AB5898 70%, #5C4BD3 80%)',
                                    transition: 'width 0.3s ease'
                                }} />
                            </div>
                            {recordings.length < 5 && (
                                <p style={{ 
                                    fontSize: '0.8em', 
                                    color: '#666', 
                                    marginTop: '8px' 
                                }}>
                                    {5 - recordings.length} more {5 - recordings.length === 1 ? 'recording' : 'recordings'} needed
                                </p>
                            )}
                        </div>
                        
                        {/* Recording controls */}
                        <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
                            <button
                                onClick={isRecording ? stopRecording : startRecording}
                                className={`main-button ${isRecording ? 'recording' : ''}`}
                                style={{ 
                                    flex: 1,
                                    backgroundColor: isRecording ? '#dc3545' : '#e1f679'
                                }}
                                disabled={recordings.length >= 5 && !isRecording}
                            >
                                {isRecording ? (
                                    <>
                                        <Square size={16} style={{ marginRight: '5px' }} />
                                        Stop Recording
                                    </>
                                ) : (
                                    <>
                                        <Mic size={16} style={{ marginRight: '5px' }} />
                                        {recordings.length >= 5 ? 'Max Recordings Reached' : 'Start Recording'}
                                    </>
                                )}
                            </button>
                        </div>

                        {/* Recording list */}
                        <div style={{ marginBottom: '20px' }}>
                            {recordings.map((recording, index) => (
                                <div 
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '8px',
                                        padding: '12px',
                                        backgroundColor: '#f8f9fa',
                                        borderRadius: '4px',
                                        marginBottom: '12px',
                                        border: '1px solid #dfe1e5'
                                    }}
                                >
                                    <div style={{ 
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        justifyContent: 'space-between',
                                        marginBottom: '4px'
                                    }}>
                                        <span style={{ 
                                            color: '#666',
                                            fontSize: '0.9em',
                                            fontWeight: '500'
                                        }}>
                                            Sample {index + 1}
                                        </span>
                                        <button
                                            onClick={() => {
                                                setRecordings(prev => prev.filter((_, i) => i !== index));
                                                if (wavesurferInstances.current[index]) {
                                                    wavesurferInstances.current[index].destroy();
                                                    delete wavesurferInstances.current[index];
                                                }
                                            }}
                                            style={{
                                                padding: '4px 8px',
                                                backgroundColor: '#dc3545',
                                                color: 'white',
                                                border: 'none',
                                                borderRadius: '4px',
                                                cursor: 'pointer',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '4px',
                                                fontSize: '0.8em'
                                            }}
                                        >
                                            <Trash2 size={14} />
                                            Delete
                                        </button>
                                    </div>
                
                                    {/* Waveform container */}
                                    <div
                                        ref={el => {
                                            waveformRefs.current[index] = el;
                                            if (el && !wavesurferInstances.current[index]) {
                                                createWaveform(recording.url, index);
                                            }
                                        }}
                                        style={{
                                            backgroundColor: 'white',
                                            padding: '8px',
                                            borderRadius: '4px',
                                            border: '1px solid #eee'
                                        }}
                                    />
                
                                    {/* Playback controls */}
                                    <div style={{ 
                                        display: 'flex', 
                                        gap: '8px',
                                        justifyContent: 'center'
                                    }}>
                                        <button
                                            onClick={() => {
                                                const wavesurfer = wavesurferInstances.current[index];
                                                if (wavesurfer) {
                                                    if (wavesurfer.isPlaying()) {
                                                        wavesurfer.pause();
                                                    } else {
                                                        wavesurfer.play();
                                                    }
                                                }
                                            }}
                                            style={{
                                                padding: '4px 12px',
                                                backgroundColor: '#1a73e8',
                                                color: 'white',
                                                border: 'none',
                                                borderRadius: '4px',
                                                cursor: 'pointer',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '4px',
                                                fontSize: '0.9em'
                                            }}
                                        >
                                            {wavesurferInstances.current[index]?.isPlaying() ? (
                                                <>
                                                    <Pause size={14} />
                                                    Pause
                                                </>
                                            ) : (
                                                <>
                                                    <Play size={14} />
                                                    Play
                                                </>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* Clone voice button */}
                        {recordings.length >= 3 && (
                            <button
                                onClick={handleVoiceCloning}
                                className="main-button"
                                disabled={isGeneratingVoice || recordings.length < 3}
                                style={{ width: '100%' }}
                            >
                                {isGeneratingVoice ? (
                                    <>
                                        <RefreshCw className="spin" size={16} />
                                        Generating Voice Clone...
                                    </>
                                ) : (
                                    'Create My AI Voice'
                                )}
                            </button>
                        )}

                        {/* Test cloned voice */}
                        {userVoiceId && (
                            <div>
                                <h4>Test Your AI Voice</h4>
                                <div style={{ display: 'flex', gap: '10px' }}>
                                    <button
                                        onClick={testClonedVoice}
                                        className="main-button"
                                        disabled={isGeneratingVoice}
                                        style={{ flex: 1 }}
                                    >
                                        {isGeneratingVoice? (
                                            <RefreshCw className="spin" size={16} />
                                        ) : (
                                            'Generate Test'
                                        )}
                                    </button>
                                    {audioUrl && (
                                        <button
                                            onClick={() => {
                                                if (audioRef.current) {
                                                    if (isPlayingVoice) {
                                                        audioRef.current.pause();
                                                    } else {
                                                        audioRef.current.play();
                                                    }
                                                    setIsPlayingVoice(!isPlayingVoice);
                                                }
                                            }}
                                            className="main-button"
                                            style={{ width: '50px' }}
                                        >
                                            {isPlayingVoice ? (
                                                <Pause size={16} />
                                            ) : (
                                                <Play size={16} />
                                            )}
                                        </button>
                                    )}
                                </div>
                                <audio
                                    ref={audioRef}
                                    src={audioUrl}
                                    onEnded={() => setIsPlayingVoice(false)}
                                    style={{ display: 'none' }}
                                />
                            </div>
                        )}
                    </div>
                </div>

            </div>

            {/* CRM List */}
            <div style={{ marginTop: '30px' }}>
                {/* LinkedIn Prospects Section */}
                <div style={{ marginTop: '40px' }}>
                    <h3>LinkedIn Prospects</h3>
                    
                    {/* Add new prospect form */}
                    <form onSubmit={handleAddProspect} style={{ marginBottom: '20px' }}>
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <input
                                type="text"
                                value={newProspectUrl}
                                onChange={(e) => setNewProspectUrl(e.target.value)}
                                placeholder="Paste LinkedIn Profile URL"
                                className={`url-input ${scrapingError ? 'error' : ''}`}
                                style={{
                                    flex: 1,
                                    padding: '10px',
                                    borderRadius: '5px',
                                    border: '1px solid #dfe1e5'
                                }}
                            />
                            <button
                                type="submit"
                                className="main-button"
                                disabled={isScrapingProfile || !newProspectUrl}
                            >
                                {isScrapingProfile ? (
                                    <>
                                        <RefreshCw className="spin" size={16} />
                                        Adding...
                                    </>
                                ) : (
                                    'Add Prospect'
                                )}
                            </button>
                        </div>
                        {scrapingError && (
                            <p style={{ color: 'red', margin: '5px 0 0' }}>{scrapingError}</p>
                        )}
                    </form>

                    {/* Prospects table */}
                    <div style={{ overflowX: 'auto' }}>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'left', padding: '12px', borderBottom: '2px solid #dfe1e5' }}>Name</th>
                                    <th style={{ textAlign: 'left', padding: '12px', borderBottom: '2px solid #dfe1e5' }}>Company</th>
                                    <th style={{ textAlign: 'left', padding: '12px', borderBottom: '2px solid #dfe1e5' }}>Job Title</th>
                                    <th style={{ textAlign: 'left', padding: '12px', borderBottom: '2px solid #dfe1e5' }}>Status</th>
                                    <th style={{ textAlign: 'left', padding: '12px', borderBottom: '2px solid #dfe1e5' }}>Custom Pitch</th>
                                    <th style={{ textAlign: 'left', padding: '12px', borderBottom: '2px solid #dfe1e5' }}>Video</th>
                                    <th style={{ textAlign: 'left', padding: '12px', borderBottom: '2px solid #dfe1e5' }}>Views</th>
                                    <th style={{ textAlign: 'left', padding: '12px', borderBottom: '2px solid #dfe1e5' }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {prospects.map(prospect => (
                                    <tr
                                        key={prospect.id}
                                        style={{
                                            backgroundColor: processingUrls.has(prospect.linkedinUrl) 
                                            ? '#f8f9fa' 
                                            : 'white'
                                        }}
                                    >
                                        <td style={{ padding: '12px', borderBottom: '1px solid #dfe1e5' }}>
                                            <a 
                                                href={prospect.linkedinUrl} 
                                                target="_blank" 
                                                rel="noopener noreferrer"
                                            >
                                                {prospect.firstName} {prospect.lastName}
                                            </a>
                                        </td>
                                        <td style={{ padding: '12px', borderBottom: '1px solid #dfe1e5' }}>
                                            {prospect.company}
                                        </td>
                                        <td style={{ padding: '12px', borderBottom: '1px solid #dfe1e5' }}>
                                            {prospect.jobTitle}
                                        </td>
                                        <td style={{ padding: '12px', borderBottom: '1px solid #dfe1e5' }}>
                                            <input
                                                type="checkbox"
                                                checked={prospect.sent}
                                                onChange={() => {
                                                    setProspects(prev =>
                                                        prev.map(p =>
                                                            p.id === prospect.id
                                                                ? { ...p, sent: !p.sent }
                                                                : p
                                                        )
                                                    );
                                                }}
                                            />
                                        </td>
                                        <td style={{ padding: '12px', borderBottom: '1px solid #dfe1e5' }}>
                                            <div style={{ position: 'relative' }}>
                                                <textarea
                                                    value={prospect.customPitch || ''}
                                                    onChange={(e) => handlePitchChange(prospect.id, e.target.value)}
                                                    style={{
                                                        width: '100%',
                                                        minHeight: '80px',
                                                        padding: '8px',
                                                        borderRadius: '4px',
                                                        border: '1px solid #dfe1e5',
                                                        marginBottom: '5px'
                                                    }}
                                                    disabled={generatingPitch.has(prospect.id)}
                                                    placeholder="Enter custom pitch or generate one..."
                                                />


                                                <div style={{ 
                                                    display: 'flex', 
                                                    justifyContent: 'space-between', 
                                                    alignItems: 'center',
                                                    gap: '10px',
                                                    marginBottom: '5px'
                                                }}>
                                                    {/* Save indicator */}
                                                    <span style={{ 
                                                        fontSize: '0.8em', 
                                                        color: '#666',
                                                        fontStyle: 'italic'
                                                    }}>
                                                        {savingPitch.has(prospect.id) ? 'Saving...' : 'Saved'}
                                                    </span>
                                                    
                                                    <div style={{ display: 'flex', gap: '10px' }}>
                                                        {/* Generate pitch button */}
                                                        <button
                                                            onClick={() => handleGeneratePitch(prospect.id)}
                                                            disabled={generatingPitch.has(prospect.id)}
                                                            style={{
                                                                padding: '4px 8px',
                                                                backgroundColor: '#1a73e8',
                                                                color: 'white',
                                                                border: 'none',
                                                                borderRadius: '4px',
                                                                cursor: generatingPitch.has(prospect.id) ? 'not-allowed' : 'pointer',
                                                                fontSize: '0.8em'
                                                            }}
                                                        >
                                                            {generatingPitch.has(prospect.id) ? (
                                                                <>
                                                                    <span className="spinner-border spinner-border-sm" />
                                                                    {' '}Generating...
                                                                </>
                                                            ) : (
                                                                'Generate Pitch'
                                                            )}
                                                        </button>
                                                        {/* Text-to-Speech button */}
                                                        <button
                                                            onClick={() => handleGenerateSpeech(prospect.id, prospect.customPitch)}
                                                            disabled={generatingSpeech.has(prospect.id) || !prospect.customPitch}
                                                            style={{
                                                                padding: '4px 8px',
                                                                backgroundColor: '#34a853',
                                                                color: 'white',
                                                                border: 'none',
                                                                borderRadius: '4px',
                                                                cursor: generatingSpeech.has(prospect.id) ? 'not-allowed' : 'pointer',
                                                                fontSize: '0.8em'
                                                            }}
                                                        >
                                                            {generatingSpeech.has(prospect.id) ? (
                                                                <>
                                                                    <span className="spinner-border spinner-border-sm" />
                                                                    {' '}Converting...
                                                                </>
                                                            ) : (
                                                                'Generate Speech'
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                                {/* Audio player */}
                                                    {audioUrls[prospect.id] && (
                                                        <audio
                                                            controls
                                                            src={audioUrls[prospect.id]}
                                                            style={{ width: '100%', marginTop: '10px' }}
                                                        >
                                                            Your browser does not support the audio element.
                                                        </audio>
                                                    )}
                                            </div>
                                        </td>
                                        <td style={{ padding: '12px', borderBottom: '1px solid #dfe1e5' }}>
                                            {prospect.videoData?.pageUrl ? (
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                                    <div style={{ 
                                                        display: 'flex', 
                                                        alignItems: 'center', 
                                                        gap: '8px',
                                                        background: '#f8f9fa',
                                                        padding: '8px',
                                                        borderRadius: '4px',
                                                        border: '1px solid #dfe1e5'
                                                    }}>
                                                        <a 
                                                            href={prospect.videoData.pageUrl} 
                                                            target="_blank" 
                                                            rel="noopener noreferrer"
                                                            style={{
                                                                color: '#1a73e8',
                                                                textDecoration: 'none',
                                                                flex: 1,
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap'
                                                            }}
                                                        >
                                                            View Landing Page
                                                        </a>
                                                        <button
                                                            onClick={() => handleCopyLink(prospect.id, prospect.videoData.pageUrl)}
                                                            style={{
                                                                padding: '4px 8px',
                                                                backgroundColor: copiedLinks.has(prospect.id) ? '#34a853' : '#1a73e8',
                                                                color: 'white',
                                                                border: 'none',
                                                                borderRadius: '4px',
                                                                cursor: 'pointer',
                                                                fontSize: '0.8em',
                                                                minWidth: '80px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                gap: '4px'
                                                            }}
                                                        >
                                                            {copiedLinks.has(prospect.id) ? (
                                                                <>
                                                                    <span style={{ fontSize: '1.2em' }}>✓</span> 
                                                                    Copied!
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <svg 
                                                                        width="14" 
                                                                        height="14" 
                                                                        viewBox="0 0 24 24" 
                                                                        fill="currentColor"
                                                                        style={{ marginRight: '4px' }}
                                                                    >
                                                                        <path d="M16 1H4C2.9 1 2 1.9 2 3v14h2V3h12V1zm3 4H8C6.9 5 6 5.9 6 7v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/>
                                                                    </svg>
                                                                    Copy Link
                                                                </>
                                                            )}
                                                        </button>
                                                    </div>
                                                    <div style={{ 
                                                        display: 'flex', 
                                                        alignItems: 'center', 
                                                        gap: '8px',
                                                        fontSize: '0.9em', 
                                                        color: '#666'
                                                    }}>
                                                        <svg 
                                                            width="16" 
                                                            height="16" 
                                                            viewBox="0 0 24 24" 
                                                            fill="currentColor"
                                                        >
                                                            <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/>
                                                        </svg>
                                                        Views: {prospect.videoData.viewCount}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <button
                                                        onClick={() => handleGenerateVideo(prospect.id)}
                                                        disabled={
                                                        generatingVideo.has(prospect.id) || 
                                                        !openingVideo || 
                                                        !closingVideo || 
                                                        !prospect.customPitch || 
                                                        !audioUrls[prospect.id]
                                                        }
                                                        className="main-button"
                                                        style={{
                                                            padding: '8px 16px',
                                                            backgroundColor: '#1a73e8',
                                                            color: 'white',
                                                            border: 'none',
                                                            borderRadius: '4px',
                                                            cursor: 'pointer',
                                                            width: '100%',
                                                            opacity: generatingVideo.has(prospect.id) ? 0.7 : 1
                                                        }}
                                                    >
                                                        {generatingVideo.has(prospect.id) ? (
                                                            <>
                                                                <span className="spinner-border spinner-border-sm" />
                                                                {' '}Generating Video...
                                                            </>
                                                        ) : (
                                                            'Generate Video Page'
                                                        )}
                                                    </button>
                                                    {(!openingVideo || !closingVideo || !prospect.customPitch || !audioUrls[prospect.id]) && (
                                                        <div style={{ 
                                                            fontSize: '0.8em', 
                                                            color: '#666', 
                                                            marginTop: '5px',
                                                            textAlign: 'center' 
                                                        }}>
                                                            Missing required elements:
                                                            <ul style={{ margin: '5px 0', paddingLeft: '20px', textAlign: 'left' }}>
                                                                {!openingVideo && <li>Opening video</li>}
                                                                {!closingVideo && <li>Closing video</li>}
                                                                {!prospect.customPitch && <li>Generated pitch</li>}
                                                                {!audioUrls[prospect.id] && <li>Generated speech</li>}
                                                            </ul>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </td>
                                        <td style={{ padding: '12px', borderBottom: '1px solid #dfe1e5', textAlign: 'center' }}>
                                            {prospect.videoData?.viewCount || 0}
                                        </td>
                                        <td style={{ padding: '12px', borderBottom: '1px solid #dfe1e5' }}>
                                            <button
                                                onClick={() => handleDeleteProspect(prospect.id)}
                                                disabled={isDeleting.has(prospect.id)}
                                                style={{
                                                    padding: '4px 8px',
                                                    backgroundColor: isDeleting.has(prospect.id) ? '#dc354580' : '#dc3545',
                                                    color: 'white',
                                                    border: 'none',
                                                    borderRadius: '4px',
                                                    cursor: isDeleting.has(prospect.id) ? 'not-allowed' : 'pointer',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '5px'
                                                }}
                                            >
                                                {isDeleting.has(prospect.id) ? (
                                                    <>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        Deleting...
                                                    </>
                                                ) : (
                                                    'Delete'
                                                )}
                                            </button>
                                        </td>
                                    </tr>
                                ))}

                                {/* Empty state message */}
                                {prospects.length === 0 && (
                                    <tr>
                                        <td colSpan="8" style={{ textAlign: 'center', padding: '40px', color: '#666' }}>
                                            No prospects added yet. Paste a LinkedIn URL above to get started.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Feedback Button */}
            <FeedbackButton username={userName} />
        </div>
    );
}

export default Dashboard;