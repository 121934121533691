import React from 'react';

const ConfidentialityRules = () => {
    return (
        <div style={{ margin: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
            <h1 style={{ color: '#333' }}>Confidentiality and Data Privacy Policy</h1>
            <p>This policy outlines our commitment to safeguarding user data, including data accessed via Google API Services. It ensures compliance with Google’s API Services User Data Policy and establishes guidelines for secure, transparent, and limited data handling.</p>
            <hr />
            <h2>1. Purpose and Scope</h2>
            <p>This Confidentiality Policy governs the handling of sensitive user data, including but not limited to data obtained via Google API Services. Our approach prioritizes transparency, limited use, and secure handling to protect user data and comply with industry standards.</p>
            <hr />
            <h2>2. Use of Google Data</h2>
            <p>We utilize Google API Services to improve user experience and provide seamless account functionality:</p>
            <ul>
                <li><strong>Identification:</strong> Authenticate accounts and identify users.</li>
                <li><strong>User Profiles:</strong> Create usernames and associate profile pictures.</li>
                <li><strong>Communication:</strong> Use email addresses for notifications and account recovery.</li>
            </ul>
            <hr />
            <h2>3. Transparency in Data Access and Use</h2>
            <ul>
                <li><strong>Accurate Representations:</strong> We clearly disclose the purpose, types of data accessed, and use cases to users during authorization and in our privacy policy.</li>
                <li><strong>Updates and Consent:</strong> If data usage changes, users will be notified and prompted for explicit consent before new use cases or permissions are implemented.</li>
            </ul>
            <hr />
            <h2>4. Data Use and Limitations</h2>
            <ul>
                <li><strong>Strictly Limited Use:</strong> User data is used only for user-facing features prominently displayed in our application.</li>
                <li><strong>No Unauthorized Sharing:</strong> We do not sell, transfer, or use data for profiling, advertising, or surveillance purposes.</li>
                <li><strong>Compliance:</strong> All usage adheres to Google’s restrictions on sensitive and restricted API scopes.</li>
            </ul>
            <hr />
            <h2>5. Minimum Permissions</h2>
            <ul>
                <li><strong>Least Privilege Principle:</strong> Permissions requested are limited to those necessary for app functionality.</li>
                <li><strong>Incremental Authorization:</strong> Data access is requested in context to ensure relevance and user understanding.</li>
                <li><strong>No Excessive Access:</strong> We avoid "future-proofing" requests or asking for unnecessary data.</li>
            </ul>
            <hr />
            <h2>6. Secure Data Handling</h2>
            <ul>
                <li><strong>Encryption and Access Control:</strong> All data is encrypted during transmission and at rest using industry-standard protocols.</li>
                <li><strong>Restricted Access:</strong> Only authorized personnel can access sensitive data, with all access logged and monitored.</li>
                <li><strong>Compliance with Security Standards:</strong> For sensitive Google API scopes, we adhere to security assessments and maintain a secure environment.</li>
            </ul>
            <hr />
            <h2>7. Data Storage and Deletion</h2>
            <ul>
                <li><strong>Retention Period:</strong> Data is retained only as long as necessary to deliver user-facing features or comply with legal requirements.</li>
                <li><strong>User Control:</strong> Users can request data deletion at any time. Upon request, data is securely and permanently removed.</li>
            </ul>
            <hr />
            <h2>8. Human Data Access</h2>
            <ul>
                <li><strong>Limited Human Access:</strong> Access by personnel is allowed only in these cases:</li>
                <ul>
                    <li>Explicit user consent (e.g., support requests).</li>
                    <li>Security investigations (e.g., abuse or bugs).</li>
                    <li>Legal obligations or aggregated operations under privacy laws.</li>
                </ul>
            </ul>
            <hr />
            <h2>9. Transparency and User Rights</h2>
            <ul>
                <li><strong>Full Visibility:</strong> Users are informed of how data is collected, used, and stored.</li>
                <li><strong>Control Options:</strong> Users can manage, download, or delete their data directly within the app or by contacting support.</li>
            </ul>
            <hr />
            <h2>10. Breach Protocol</h2>
            <ul>
                <li><strong>Rapid Response:</strong> Users are promptly informed of data breaches.</li>
                <li><strong>Risk Mitigation:</strong> Immediate measures are taken to secure data and minimize impact.</li>
            </ul>
            <hr />
            <h2>11. Confidentiality Agreements</h2>
            <ul>
                <li><strong>Employee and Contractor Agreements:</strong> Personnel and partners must sign confidentiality agreements to safeguard user data.</li>
                <li><strong>Periodic Training:</strong> Regular training reinforces best practices in data privacy and security.</li>
            </ul>
            <hr />
            <h2>12. Compliance with Google API Services User Data Policy</h2>
            <p>We fully comply with Google’s policy by ensuring:</p>
            <ul>
                <li><strong>Accurate Representations:</strong> Clearly communicating who we are, what data we access, and why.</li>
                <li><strong>Minimized Permissions:</strong> Requesting only the permissions necessary for app functionality.</li>
                <li><strong>Limited Use:</strong> Using Google API data solely for visible, user-facing features.</li>
                <li><strong>Secure Handling:</strong> Encrypting all data and preventing unauthorized access.</li>
            </ul>
            <hr />
            <p>For questions or concerns, please contact us at <strong>boukar.o.sall[at]gmail.com</strong>.</p>
        </div>
    );
};

export default ConfidentialityRules;