import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Tesseract from 'tesseract.js';
import api from './api';

function FileUploadDark() {
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [fileUrl, setFileUrl] = useState('');
    const [extractedText, setExtractedText] = useState('');
    const [additionalContext, setAdditionalContext] = useState('');
    const [query, setQuery] = useState('');
    const [response, setResponse] = useState('');

    // Handle file selection via file input or drag-and-drop
    const handleFileChange = (file) => {
        setFile(file);
        setMessage(`${file.name} is ready to upload.`);
        performOCR(file);
    };

    const performOCR = async (file) => {
        setMessage('Performing OCR...');
        try {
            const { data: { text } } = await Tesseract.recognize(file, 'eng');
            setExtractedText(text);
            setMessage('OCR completed successfully!');
        } catch (error) {
            console.error('Error during OCR:', error);
            setMessage('Error extracting text from image');
        }
    };


    const handleUpload = async () => {
        if (!file) {
            setMessage('Please select a file first');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('extractedText', extractedText);
        formData.append('additionalContext', additionalContext);

        try {
            const response = await api.post('/upload', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            setMessage('File uploaded successfully!');
            setFileUrl(response.data.fileUrl); // Get the file URL from the response
            setFile(null); // Clear the file after upload
            setExtractedText(''); // Clear the extracted text
            setAdditionalContext(''); // Clear the context field
        } catch (error) {
            console.error(error);
            setMessage('Error uploading file');
        }
    };

    const [isQuerying, setIsQuerying] = useState(false);

    const handleQuery = async () => {

        setIsQuerying(true); // Set loading state
        setResponse(''); // Clear the response area

        try {
            const res = await api.post('/query-knowledge', {
                username: 'Bouki', // Replace with dynamic username if needed
                query,
            });
            setResponse(res.data.answer);
        } catch (error) {
            console.error('Error querying knowledge:', error);
            setResponse('Failed to retrieve an answer. Please try again.');
        } finally {
            setIsQuerying(false); // Reset loading state
        }
    };

    // React-Dropzone for drag-and-drop functionality
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                handleFileChange(acceptedFiles[0]); // Handle only the first file for simplicity
            }
        },
        multiple: false, // Allow only one file to be uploaded at a time
    });

    return (
        <div style={{backgroundColor:'#161616', padding:'25px'}}>
            <h1 style={{color:'#D1D1D1'}}>Merra.ai</h1>
            <h2 style={{color:'#D1D1D1'}}>Let's <span style={{backgroundColor: '#5E799F', color:'#161616', borderRadius: '8px', paddingLeft: '5px', paddingRight: '5px'}}>work on your dream</span> together</h2>
            <div style={{ marginBottom: '20px', marginTop:'50px' }}>
                <h3 style={{color:'#D1D1D1'}}>Query Knowledge</h3>
                <input
                    type="text"
                    placeholder="Ask a question..."
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    style={{ width: '95%', marginBottom: '10px', padding: '10px', borderRadius:'10px', backgroundColor:'#1E1E1E', color:'white'}}
                />
                <button
                    onClick={handleQuery}
                    style={{
                        marginBottom: '10px',
                        backgroundColor: '#E1F679',
                        color: 'black',
                        fontWeight: 'bold',
                        border: 'none',
                        borderRadius: '10px',
                        padding: '10px 15px',
                        cursor: 'pointer',
                    }}
                >
                    Submit Query
                </button>
                {response || isQuerying ?  (
                    <div>
                        <h4>Response:</h4>
                        <textarea
                            value={isQuerying ? 'Typing...' : response}
                            readOnly
                            style={{ width: '95%', height: '150px', padding: '10px', borderRadius:'10px' }}
                        />
                    </div>
                ) : null }
            </div>
            
            <hr class="dashed"/>
            <h3 style={{color:'#D1D1D1', marginTop:'50px'}}>File Upload to add to the knowledge:</h3>
            {/* Drag-and-Drop Area */}
            <div
                {...getRootProps()}
                style={{
                    border: '2px dashed #007bff',
                    width:'90%',
                    borderRadius: '8px',
                    padding: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                    alignSelf:'center',
                    justifySelf:'center',
                    backgroundColor: isDragActive ? '#f0f8ff' : '#1E1E1E',
                }}
            >
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p style={{color:'white'}}>Drop the file here...</p>
                ) : (
                    <p style={{color:'white'}}>Drag & drop a file here, or click to select one</p>
                )}
            </div>

            {/* Manual File Selection */}
            {/* <input type="file" onChange={(e) => handleFileChange(e.target.files[0])} /> */}
            <textarea
                placeholder="Add additional context..."
                value={additionalContext}
                onChange={(e) => setAdditionalContext(e.target.value)}
                style={{ width: '95%', height: '100px', marginTop: '20px', borderRadius:'10px', padding:'10px', backgroundColor:'#1E1E1E', color:"white"}}
            />
            <button
                onClick={handleUpload}
                style={{ 
                    marginTop: '20px',
                    backgroundColor: '#E1F679',
                    color: 'black',
                    fontWeight: 'bold',
                    border: 'none',
                    borderRadius: '10px',
                    padding: '10px 15px',
                    cursor: 'pointer',
                }}>
                Upload
            </button>

            {/* Status Message */}
            {message && <p>{message}</p>}

            {/* Extracted Text */}
            {extractedText && (
                <div>
                    <h2>Extracted Text</h2>
                    <p>{extractedText}</p>
                </div>
            )}

            {/* Uploaded File URL */}
            {fileUrl && (
                <p>
                    File URL: <a href={fileUrl}>{fileUrl}</a>
                </p>
            )}
        </div>
    );
}

export default FileUploadDark;