import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

const AMPLITUDE_API_KEY = 'fc36fcc0028992fc512a8d239b6626ad';

export const initAmplitude = () => {
  console.log('Initializing Amplitude...'); // Debug log
  // Add session replay plugin
  amplitude.add(sessionReplayPlugin({
    sampleRate: 1
  }));

  // Initialize Amplitude
  amplitude.init(AMPLITUDE_API_KEY, {
    serverZone: 'EU',
    autocapture: {
      elementInteractions: true
    },
    logLevel: 3,  // Enable debug logging (0: NONE, 1: ERROR, 2: WARN, 3: INFO, 4: DEBUG)
  });

  // Test event to verify setup
  amplitude.track('app_initialized', {
    timestamp: new Date().toISOString()
  });
};

// Utility function to track events
export const trackEvent = (eventName, eventProperties = {}) => {
  amplitude.track(eventName, eventProperties);
};